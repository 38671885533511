
    body {
        margin: 0;
        padding: 0;
        /* overflow-x: hidden;
        overflow-y: hidden; */
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90vh;
    }

    .topImage{
        height: 15vh;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .ck{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .attribution {
        height: 4vh;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .attribution img {
        max-height: 100%;
        object-fit: contain; /* or cover, depending on your needs */
    }
